import React, { useState, useRef, useEffect } from "react";
import "./searchForm.scss";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { base_url } from "../../helper/axios";
import LazyImage from "../lazy Image/LazyImage";

const SearchForm = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState({
    movies: [],
    series: [],
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const handleClick = (path) => {
    navigate(path);
    setShowDropdown(false); // Close dropdown after navigating
  };

  const handleSearchChange = async (e) => {
    const q = e.target.value;
    setSearchQuery(q);
    if (q.trim() !== "") {
      try {
        const response = await axios.get(`${base_url}/api/search?q=${q}`);
        setSearchResults(response.data);
        setShowDropdown(true);
      } catch (error) {
        console.error("Error searching:", error);
      }
    } else {
      setSearchResults({ movies: [], series: [] }); // Clear search results
      setShowDropdown(false);
    }
  };

  const handleDropdownItemClick = (type, movieId, season) => {
    let newPath = `/${type}-watch/${movieId}`;
    if (type === "serie" && season) {
      newPath = `/${type}-w/${movieId}/${season}`;
    }
    handleClick(newPath);
    setSearchQuery("");
  };
  // ********************************************** COUNTS OF MOVIES AND SERIES ****************
  const [moviesCount, setMovieCount] = useState(0);
  const [seriesCount, setSeriesCount] = useState(0);
  const fetchCounts = async () => {
    try {
      const response = await axios.get(`${base_url}/api/counts`);
      setMovieCount(response.data.movieCount);
      setSeriesCount(response.data.seriesCount);
      console.log(moviesCount);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    fetchCounts(); // Fetch counts when the component mounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const placeholderText = `ابحث عن ${moviesCount}  من الافلام و  ${seriesCount} من المسلسلات `;

  return (
    <div className="search">
      <input
        value={searchQuery}
        onChange={handleSearchChange}
        onFocus={() => {
          if (searchQuery.trim().length > 0) {
            setShowDropdown(true);
          }
        }}
        type="text"
        placeholder={placeholderText}
        id="search"
      />
      <button type="button">
        <FaSearch className="icon" />
      </button>
      {showDropdown && (
        <div ref={dropdownRef} className="dropdown-menu">
          {searchResults?.movies?.length > 0 && (
            <div className="dropdown-section" dir="auto">
              <h3>افلام</h3>
              {searchResults?.movies?.map((movie) => (
                <Link
                  className="result-container"
                  to={`/movie-watch/${movie?.movieId}`}
                  key={movie.id}
                  onClick={() =>
                    handleDropdownItemClick("movie", movie?.movieId)
                  }>
                  <LazyImage
                    className="searchImage"
                    imageUrl={movie?.movieImage}
                    onError={(e) => {
                      e.target.src =
                        "https://images.pexels.com/photos/4722571/pexels-photo-4722571.jpeg?auto=compress&cs=tinysrgb&w=600";
                    }}
                    alt=""
                  />
                  <div className="title">
                    {movie?.title}
                    <span>{movie?.category}</span>
                  </div>
                </Link>
              ))}
            </div>
          )}
          {searchResults?.series?.length > 0 && (
            <div className="dropdown-section" dir="auto">
              <h3>مسلسلات</h3>
              {searchResults?.series?.map((series) => (
                <Link
                  className="result-container"
                  to={
                    series?.season === false
                      ? `/serie-w/${series?.serieId}`
                      : `/serie-watch/${series?.serieId}`
                  }
                  key={series?.id}>
                  <LazyImage
                    className="searchImage"
                    imageUrl={series?.serieImage}
                    onError={(e) => {
                      e.target.src =
                        "https://images.pexels.com/photos/4722571/pexels-photo-4722571.jpeg?auto=compress&cs=tinysrgb&w=600";
                    }}
                    alt=""
                  />
                  <div className="title">
                    {series?.serieTitle}
                    <span>{series?.category}</span>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchForm;
