import React from "react";
import "./terms.scss";

const Terms = () => {
  return (
    <div class="terms">
      <h1>Code of Conduct</h1>

      <h2>Behavior</h2>
      <p>
        In using this Service, you must behave in a civil and respectful manner
        at all times. Further, you will not:
      </p>
      <ul>
        <li>
          Act in a deceptive manner by, among other things, impersonating any
          person;
        </li>
        <li>Harass or stalk any other person;</li>
        <li>Harm or exploit minors;</li>
        <li>Distribute "spam";</li>
        <li>Collect information about others; or</li>
        <li>
          Advertise or solicit others to purchase any product or service within
          the Site (unless you are an official partner or advertiser and have a
          written agreement with us).
        </li>
      </ul>

      <p>
        The Site owner has the right, but not the obligation, to monitor all
        conduct on and content submitted to the Service.
      </p>

      <h2>Content Restrictions</h2>
      <p>
        You may not upload, post, or transmit (collectively, "submit") any
        video, image, text, audio recording, or other work (collectively,
        "content") that:
      </p>
      <ul>
        <li>
          Infringes any third party's copyrights or other rights (e.g.,
          trademark, privacy rights, etc.);
        </li>
        <li>
          Contains sexually explicit content or pornography (provided, however,
          that non-sexual nudity is permitted);
        </li>
        <li>
          Contains hateful, defamatory, or discriminatory content or incites
          hatred against any individual or group;
        </li>
        <li>Exploits minors;</li>
        <li>Depicts unlawful acts or extreme violence;</li>
        <li>Depicts animal cruelty or extreme violence towards animals;</li>
        <li>
          Promotes fraudulent schemes, multi level marketing (MLM) schemes, get
          rich quick schemes, online gaming and gambling, cash gifting, work
          from home businesses, or any other dubious money-making ventures; or
        </li>
        <li>Violates any law.</li>
      </ul>
    </div>
  );
};

export default Terms;
