import React, { useEffect, useRef, useState } from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";
import { moviesLinks, seriesLinks, websiteName } from "../../data";
import { CiMenuBurger } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import SearchForm from "../../components/searchForm/SearchForm";
import axios from "axios";
import { base_url } from "../../helper/axios";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const [availableMoviesLinks, setAvailableMoviesLinks] = useState([]);
  const [availableSeriesLinks, setAvailableSeriesLinks] = useState([]);

  useEffect(() => {
    const checkMoviesLinks = async () => {
      const tempMoviesLinks = [];

      // Loop through movies links
      for (const link of moviesLinks) {
        try {
          const response = await axios.get(
            `${base_url}/api/cateMovies/${link?.category}`
          );

          if (response.data.length > 0) {
            // If the category has items, push it to tempMoviesLinks
            tempMoviesLinks.push(link);
          }
        } catch (error) {
          console.error(`Error fetching movies for ${link?.category}:`, error);
        }
      }

      // Update state with available movie links
      setAvailableMoviesLinks(tempMoviesLinks);
    };

    const checkSeriesLinks = async () => {
      const tempSeriesLinks = [];

      // Loop through series links
      for (const link of seriesLinks) {
        try {
          const response = await axios.get(
            `${base_url}/api/cateSeries/${link.category}`
          );

          if (response.data.length > 0) {
            // If the category has items, push it to tempSeriesLinks
            tempSeriesLinks.push(link);
          }
        } catch (error) {
          console.error(`Error fetching series for ${link.category}:`, error);
        }
      }

      // Update state with available series links
      setAvailableSeriesLinks(tempSeriesLinks);
    };

    checkMoviesLinks();
    checkSeriesLinks();
  }, []); // Only run once on component mount

  useEffect(() => {
    const handler = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    const handleScroll = () => {
      if (open) {
        setOpen(false); // Close the menu on scroll
      }
    };

    document.addEventListener("mousedown", handler);
    window.addEventListener("scroll", handleScroll); // Add scroll event listener

    return () => {
      document.removeEventListener("mousedown", handler);
      window.removeEventListener("scroll", handleScroll); // Clean up on unmount
    };
  }, [open]);
  const handleClick = () => {
    setOpen((prevState) => !prevState);
  };

  const handleLinkClick = () => {
    setOpen(false);
  };

  return (
    <div className="nav-container">
      <nav className="navbar">
        <div className="container">
          <div className="logo df-center-x-y roboto-bold">
            <Link to="/" className="logo" onClick={handleLinkClick}>
              {websiteName}
            </Link>
          </div>
          <button className="burger df-center-x-y" onClick={handleClick}>
            {open ? (
              <IoMdClose className="icon" />
            ) : (
              <CiMenuBurger className="icon" />
            )}
          </button>
        </div>
      </nav>
      <SearchForm />
      <div
        ref={menuRef}
        className={`nav-menu ${open ? "nav-menu-open" : "nav-menu-close"}`}>
        <h4 className="df-center-x-y roboto-bold">افلام</h4>
        <ul>
          {availableMoviesLinks?.map((item, idx) => (
            <Link
              key={idx}
              onClick={handleLinkClick}
              className="navLink df-center-x-y roboto-bold"
              to={item?.path}>
              {item?.title}
            </Link>
          ))}
        </ul>
        <h4 className="df-center-x-y roboto-bold">مسلسلات</h4>
        <ul>
          {availableSeriesLinks?.map((item, idx) => (
            <Link
              key={idx}
              to={item?.path}
              onClick={handleLinkClick} // Close menu on link click
              className="navLink df-center-x-y roboto-bold">
              {item?.title}
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
