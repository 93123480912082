import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { base_url } from "../../helper/axios";

const LazyImage = ({ className, imageUrl }) => {
  const [imageSrc, setImageSrc] = useState(imageUrl);
  const [imageError, setImageError] = useState(false);

  const handleImageError = (error) => {
    setImageError(true);
    console.log(error);
  };

  return (
    <>
      {imageError ? (
        <LazyLoadImage
          effect="opacity"
          // src={`${imageUrl}`}
          src={`https://images.pexels.com/photos/4722571/pexels-photo-4722571.jpeg?auto=compress&cs=tinysrgb&w=600`}
          alt="Your image alt text"
          // height={"600px"}
          className={className}
          loading="lazy"
        />
      ) : (
        <LazyLoadImage
          effect="opacity"
          src={`${base_url}/images${imageUrl}`}
          alt="Your image alt text"
          // height={"300px"}
          className={className}
          loading="lazy"
          onError={(error) => handleImageError(error)}
        />
      )}
    </>
  );
};

export default LazyImage;
