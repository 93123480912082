import React, { Suspense, useEffect } from "react";
import "./app.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { googleAnaly, moviesLinks, seriesLinks } from "./data";
import Navbar from "./layout/navbar/Navbar";
import Footer from "./layout/footer/Footer";
import Terms from "./pages/terms/Terms";
import ReactGA from "react-ga";
import NotFound from "./pages/notFound/NotFound";
// import AdComp from "./layout/ads/AdComp";

// Lazy-loaded components
const LazyHome = React.lazy(() => import("./pages/Home"));
const LazyCategory = React.lazy(() => import("./pages/categoryPage/Category"));
const LazyCategoryMovies = React.lazy(() =>
  import("./pages/categoryPage/CategoryMovies")
);
const LazyMovieWatch = React.lazy(() =>
  import("./pages/movieWatch/MovieWatch")
);
const LazyMoviePlay = React.lazy(() => import("./pages/moviePlay/MoviePlay"));
const LazySerieWatch = React.lazy(() =>
  import("./pages/serieWatch/SerieWatch")
);
const LazySeriePlay = React.lazy(() => import("./pages/seriePlay/SeriePlay"));
const LazyWithoutSeasons = React.lazy(() =>
  import("./components/WithoutSeasons/WithoutSeasons")
);
const LazyWithoutSeasonsPlay = React.lazy(() =>
  import("./components/withoutSeasonPlay/WithoutSeasonsPlay")
);

const DMCE = React.lazy(() => import("./pages/DMCA/DMCA"));

// Initialize Google Analytics
ReactGA.initialize(googleAnaly);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <title>CimaFlix | أفضل موقع لمشاهدة الأفلام والمسلسلات</title>
          <meta
            name="description"
            content="CimaFlix لمشاهدة وتحميل الأفلام والمسلسلات بجودة عالية HD."
          />
          <meta
            name="keywords"
            content="CimaFlix, أفلام, مسلسلات, برامج تلفزيونية"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="CimaFlix" />
          <meta property="og:locale" content="ar_AR" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Navbar />
        <div className="background-overlay"></div>
        <Suspense
          fallback={
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          }>
          <Routes>
            <Route path="/" element={<LazyHome />} />
            {moviesLinks.map((link, idx) => (
              <Route
                key={idx}
                path={link.path}
                element={
                  <LazyCategoryMovies
                    title={link.title}
                    category={link.category}
                    catTitle={link.title}
                  />
                }
              />
            ))}
            {seriesLinks.map((link, idx) => (
              <Route
                key={idx}
                path={link.path}
                element={
                  <LazyCategory
                    title={link.title}
                    category={link.category}
                    catTitle={link.title}
                  />
                }
              />
            ))}
            {/* .................................................................... */}
            <Route path="/movie-watch/:movieId" element={<LazyMovieWatch />} />
            <Route path="/movie-play/:movieId" element={<LazyMoviePlay />} />
            {/* .................................................................... */}
            <Route path="/serie-watch/:serieId" element={<LazySerieWatch />} />
            <Route
              path="/serie-watch/:serieId/serie-play/:season/:episode"
              element={<LazySeriePlay />}
            />
            {/* .................................................................... */}
            <Route path="/serie-w/:serieId" element={<LazyWithoutSeasons />} />
            <Route
              path="/serie-W/:serieId/serie-P/:episode"
              element={<LazyWithoutSeasonsPlay />}
            />
            {/* .................................................................... */}
            <Route path="/DMCA" element={<DMCE />} />
            <Route path="/terms" element={<Terms />} />
            {/* 404 Not Found Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Footer />
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
